
new WOW({ offset: 100 }).init();


$(document).ready(function () {

	if ($('.homepage-marker').length > 0) {
		if (window.matchMedia('(min-width:768px)').matches) {
			$('.header__logo').addClass('wow bounceInLeft');
		}
	}

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.testimonials__panel').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on('resize', function () {
		setTimeout(fixHeights, 300);
	});

	$('.textContent h1 ').addClass('splitter');
	$('.splitter').addClass('wow');

	var splitter = document.querySelectorAll('.splitter');

	Splitting({
		target: splitter,
	});

});

